<script setup lang="ts">
const props = defineProps({
  disabled: { type: Boolean, default: false },
  class: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <button :class="`primary ${props.class} ${props.disabled ? 'disabled' : ''}`">
    <slot />
  </button>
</template>

<style scoped></style>
